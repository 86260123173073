import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultationRequestFormComponent } from '@components/consultation-request/consultation-request-form/consultation-request-form.component';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Gender } from '@enums/gender';
import { ConsultationRequestFormAlreadySubmittedError } from '@errors/consultation-request-form-already-submitted-error';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { SessionStorageService } from '@services/session-storage.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { from, Observable, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-consultation-std-prevention',
  templateUrl: './std-prevention-consultation-request-form.component.html',
  styleUrls: [
    './../consultation-request/consultation-request.component.scss',
    'std-prevention-consultation-request-form.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class StdPreventionConsultationRequestFormComponent extends ConsultationRequestFormComponent implements OnInit {
  /**
   * Gets the preferred medication form control.
   */
  get preferredMedication(): string {
    return 'Doxycycline';
  }

  /**
   * Get the FormGroup for the std prevention consultation form
   */
  get consultationRequestForm(): FormGroup {
    return this.formService.stdPreventionConsultationRequest;
  }

  /**
   * Gets the date of birth.
   */
  get dob(): Date | null {
    return this.consultationRequestOrder.date_of_birth ? new Date(this.consultationRequestOrder.date_of_birth) : null;
  }

  /**
   * Get the FormGroup for the terms section
   */
  get termsForm(): FormGroup {
    return this.consultationRequestForm.get('terms') as FormGroup;
  }

  /**
   * Return true if the current patient is female
   */
  get isFemale(): boolean {
    return this.patientInfoForm.value.gender === Gender.Female;
  }

  /**
   * Gets the external validation data for the dynamic questionnaire form.
   */
  get externalData(): ExternalData {
    return {
      gender: this.patientInfoForm.value.gender,
    };
  }

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected consultationRequestService: ConsultationRequestService,
    protected domainService: DomainService,
    protected activatedRoute: ActivatedRoute,
    protected loadingService: LoadingService,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService,
    protected router: Router,
    protected treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService,
    protected dynamicFormsService: DynamicFormsService,
    protected errorHandleService: ErrorHandlerService
  ) {
    super(
      consultationRequestService,
      dynamicFormsService,
      errorHandleService,
      loadingService,
      activatedRoute,
      sessionStorageService
    );
  }

  ngOnInit(): void {
    this.handleConsultationOrderDetails();
  }

  /**
   * Toggle the validators for the "isFemale" controls
   *
   * @param {"F"|"M"} gender
   */
  toggleIsFemaleValidators(gender: 'F' | 'M'): void {
    this.termsForm.get('tos3').removeValidators(Validators.requiredTrue);
    if (gender === 'F') {
      this.termsForm.get('tos3').setValidators(Validators.requiredTrue);
    }

    this.termsForm.get('tos3').updateValueAndValidity();
  }

  /**
   * Submit the std prevention consultation form
   */
  submit(): void {
    if (!this.shouldContinue()) {
      return;
    }

    this.isProcessing = true;
    this.storeConsultationRequestCompletedData();
    this.updateConsultation().subscribe({
      next: () => this.onRequestSuccess(),
      error: () => this.onRequestError(),
    });
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  protected storeConsultationRequestCompletedData(): void {
    super.storeConsultationRequestCompletedData();
    this.sessionStorageService.treatmentType = this.consultationRequestTreatmentType;
  }

  /**
   * Validate the consultation form
   */
  private shouldContinue(): boolean {
    this.submissionError = null;
    if (!this.isFormValid()) {
      this.submissionError = new MissingFormFieldsError().message;
      if (!this.pharmacyForm.value.id) {
        this.submissionError = `Please select a pharmacy.`;
      }

      this.markAllFormControlsAsTouched();

      return false;
    }

    return true;
  }

  /**
   * Redirect to the consultation complete page
   */
  private onRequestSuccess(): void {
    this.router.navigateByUrl('/std-prevention/consultation-request-completed');
  }

  /**
   * Set the request error message
   */
  private onRequestError(): void {
    this.isProcessing = false;
    this.submissionError = `There was an error processing your request. Please contact customer support.`;
  }

  /**
   * Determines if the patient has disqualifying answers in the questionnaire.
   *
   * @returns {boolean} true if the patient has disqualifying answers, otherwise false
   */
  protected getConsultationStatus(): ConsultationStatus {
    return this.hasDisqualifyingAnswers() ? ConsultationStatus.Disqualified : ConsultationStatus.Pending;
  }

  /**
   * Marks all form controls as touched.
   */
  private markAllFormControlsAsTouched(): void {
    this.medicalQuestionsForm.form.markAllAsTouched();
    this.medicalHistoryForm.markAllAsTouched();
    this.consultationRequestForm.markAllAsTouched();
  }

  /**
   * Checks if the forms displayed on the page are valid.
   *
   * @returns {boolean} true if all forms are valid, otherwise false
   */
  private isFormValid(): boolean {
    return this.consultationRequestForm.valid && this.medicalQuestionsForm.form.valid && this.medicalHistoryForm.valid;
  }

  /**
   * Update the consultation request
   */
  private updateConsultation(): Observable<any> {
    return from(this.treatmentConsultationQuestionnaireService.getConsultationRequestPayload()).pipe(
      switchMap((consultationRequestPayload) => {
        return this.consultationRequestService.update(
          this.consultationRequestService.consultationOrderDetail.consultationRequest.id.toString(),
          this.consultationRequestOrder.transaction_id,
          this.consultationRequestOrder.hash,
          consultationRequestPayload
        );
      })
    );
  }

  /**
   * Handles the success of the consultation order details resolver.
   *
   * @param {ConsultationRequestOrderDetail} consultationOrderDetails The consultation order details
   */
  protected handleConsultationDetailsSuccess(consultationOrderDetails: ConsultationRequestOrderDetail): void {
    this.subscribeToGenderChanges();
    this.setQuestions().then(() => this.loadingService.toggleLoader(false));
  }

  /**
   * Subscribe to gender changes in the patient form
   */
  private subscribeToGenderChanges(): void {
    this.consultationRequestForm
      .get('patient')
      .get('gender')
      .valueChanges.subscribe((gender) => this.toggleIsFemaleValidators(gender));
  }
}
