import { NgComponentOutlet } from '@angular/common';
import { Component, Type } from '@angular/core';
import { ConsultationRequestFormComponent } from '@components/consultation-request/consultation-request-form/consultation-request-form.component';
import { DynamicConsultationRequestFormComponent } from '@components/consultation-request/dynamic-consultation-request-form/dynamic-consultation-request-form.component';
import { StdPreventionConsultationRequestFormComponent } from '@components/std-prevention-consultation-request-form/std-prevention-consultation-request-form.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-consultation-page',
  standalone: true,
  imports: [NgComponentOutlet],
  template: `<ng-container *ngComponentOutlet="getConsultationComponent()" />`,
})
export class ConsultationRequestPageComponent {
  constructor(private sessionStorage: SessionStorageService) {}

  /**
   * Gets the consultation component based on the treatment type
   */
  getConsultationComponent(): Type<ConsultationRequestFormComponent> {
    switch (this.sessionStorage.treatmentType) {
      case ConsultationTreatmentTypes.StdPrevention:
        return StdPreventionConsultationRequestFormComponent;
      default:
        return DynamicConsultationRequestFormComponent;
    }
  }
}
